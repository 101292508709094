html, .font-serif , body {
  font-family: Montserrat !important;
}

.bg-acto {
  background-image: url('/src/assets/img/navbar.svg');
  background-size: cover;
  background-repeat: no-repeat;
  text-shadow: 1px 1px 0px rgb(255 255 255 / 80%);
  height: 8.5rem;
  background-position-y: bottom;
  background-position-x: 10%;
}

.bg-color-acto {
  background: linear-gradient(90deg, rgba(69,16,138,1) 0%, rgba(69,16,138,1) 10%, rgba(124,29,201,1) 100%);
}

.bg-color-acto-vertical {
  background: linear-gradient(180deg, rgba(69,16,138,1) 0%, rgba(69,16,138,1) 10%, rgba(124,29,201,1) 100%);
}

button:focus {
  outline: none !important;
}

.bg-blue-acto {
  background-image: url('/src/assets/img/bg-azul.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  height: calc(100vw*0.575);
  margin-top: -6.25rem;
}

.bg-free {
  background-image: url('/src/assets/img/bg-free.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 1079px;
  margin-top: 7rem;
}

.bg-style {
  background-image: url('/src/assets/img/bg-style.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  margin-top: 13.125rem;
  height: 926px;
}

.text-md {
  font-size: 1rem;
  line-height: 1.25rem;
}

.leading-none-forced {
  line-height: 1 !important;
}

.text-acto {
  color: #5900B4; 
}
.text-blue {
  color: #007EFF;
}
.text-pink {
  color: #9D00FF;
}

.text-acto-purple {
  color: #45108A;
}

.text-menu {
  color: #58595B;
  font-weight: 500;
}

.text-yellow {
  color: #F1BE14;
}

.text-warning {
  color: #FF4E6A;
  font-weight: bold;
}

.bg-form {
  position: relative;
  width: 502px;
  height: 644px;
  margin-top: 0.5rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 20px -10px rgba(0,0,0,1);
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0,0,0,1);
  z-index: 2;
  background-color: white;
  padding: 1.5rem;
}

.behind {
  position: absolute;
  width: 326px;
  height: 395px;
  background-color: #1494F1;
  margin: 2.625rem 0rem 0rem 13rem;
  border-radius: 1rem;
  z-index: 1;
}


.bg-blue-light {
  background-color: #E8F2F8;
}

.logo-center {
  position: absolute;
  left: 9%;
}

.bg-faro {
  background-image: url('/src/assets/img/bg-faro.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  height: calc(100vw*0.637);
  margin-top: -2.625rem;
}

.bg-faro.md-hidden .container {
  padding-top: 15.125rem;
}

.py-7 {
 padding-top: 1.75rem;
 padding-bottom: 1.75rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.btn-acto {
  width: 432px;
  border: 2px solid #5900B4;
  background-color: white;
  color: #5900B4;
  text-transform: none;
  font-size: 24px;
  border-radius: 21px;
  padding: 18px;
}

.btn-acto-pink {
  background-color: #9300FF; 
  width: 100%;
  text-transform: none;
  margin-top: 26px;
}

.mb-19 {
  margin-bottom: 4.75rem;
}

.mt-18 {
  margin-top: 4.5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.bg-app {
  background-image: url('/src/assets/img/bg-app.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: calc(100vw*0.568);
  margin-top: 10.625rem;
}

.bg-app-benefits {
  background-image: url('/src/assets/img/bg-app.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: calc(100vw*0.55);
  margin-bottom: 5.25rem;
}

.bg-app-benefits .container {
  padding-top: 12rem;
}

.bg-app .container {
  padding-top: 4rem;
}


.text-normal {
  text-transform: none;
}

.text-italic {
  font-style: italic;
}

.input-b2b {
  border: 1px solid #707070;
  color: #333333;
}

.bg-assistance {
  background-image: url('/src/assets/img/bg-assistance.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: calc(100vw*0.89);
}

.bg-assistance.md-hidden .container {
  padding-top: 1.375rem;
}

.bg-acto-play {
  background-color: #45108A;
  border-radius: 4rem;
  width: 967px;
  height: 563px;
}

.bubble-acto-purple {
  background-color: #25054D;
  width: 340px;
  border-radius: 15px;
  padding: 20px 30px;
  color: #9DC4DF;
}

.bubble-acto-pink {
  background-color: #45108A;
  width: 340px;
  border-radius: 15px;
  padding: 20px 30px;
  color: #9DC4DF;
}

.bg-protection {
  background-image: url('/src/assets/img/bg-protection.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  height: calc(100vw*0.94);
}

.bg-suscriptors {
  background-image: url('/src/assets/img/bg-suscriptors.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  height: calc(100vw*0.598);
  margin-top: 6rem;
}

.bg-suscriptors .container{
  padding-top: 7.5rem;
}

.bg-benefits-assistance {
  background-image: url('/src/assets/img/benefits/assistance.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  height: calc(100vw*0.544);
  margin-top: 3.375rem;
}

.bg-benefits-protections {
  background-image: url('/src/assets/img/benefits/protections.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: calc(100vw*0.7);
  margin-bottom: 5rem;
}

.bg-space {
  background-image: url('/src/assets/img/benefits/space.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
}

.bg-experience {
  background-image: url('/src/assets/img/experience.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 70rem;
  margin-bottom: -11rem;
}

.bg-moment {
  background-image: url('/src/assets/img/bg-moment.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: calc(100vw*0.525);
}

.bg-moment.md-hidden .container {
  padding-top: 10rem;
}

.bg-hiking {
  background-image: url('/src/assets/img/slider/hikingMobile.png');
  background-size: cover;
  background-position: center;
}

.bg-doctor {
  background-image: url('/src/assets/img/slider/doctor.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-thief {
  background-image: url('/src/assets/img/slider/thief.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-family {
  background-image: url('/src/assets/img/slider/family.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-car {
  background-image: url('/src/assets/img/slider/car.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-hurt {
  background-image: url('/src/assets/img/slider/broken.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-phone {
  background-image: url('/src/assets/img/phone/phone.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 1114px;
}

.height-slider {
  height: 698px;
}

.h-half{
  height: 50%;
}

button.makeStyles-fullHeightHoverButton-10 svg.MuiSvgIcon-root {
  width: auto;
  height: auto;
}

.list-disc {
  list-style-type: disc;
}

.line-28 {
  line-height: 1.75rem;
}

.md\:w-7\/12 {
  width: 58.333333%
}

.text-xl {
	font-size: 1.25rem !important;
	line-height: 1.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-4xl {
  border-radius: 2rem;
}

.rounded-6xl {
  border-radius: 3rem;
}

.btn-20 {
  width: 11rem;
  height: 3.125rem;
}

.btn-17 {
  width: 17.5rem;
  height: 3.25rem;
}

.pb-120 {
  padding-bottom: 30rem;
}

.p-1 {
  padding: 0.3rem !important;
}

.lg\:text-22 {
  font-size: 1.375rem;
	line-height: 2rem;
}

.text-40 {
  font-size: 2.5rem;
  line-height: 1.25;
}

.text-42 {
  font-size: 2.625rem;
}

.text-26 {
  font-size: 1.625rem;
}

.text-22 {
  font-size: 1.375rem;
}

.text-30 {
  font-size: 1.875rem;
}

.text-48 {
  font-size: 3rem;
}

.text-56 {
  font-size: 3.5rem;
  line-height: 4rem;
}

.text-60 {
  font-size: 3.75rem;
}

.text-77 {
  font-size: 4.8125rem;
}

.text-8 {
  font-size: 0.5rem;
  line-height: 1;
}

.NavbarContainer .lg\:justify-start {
  justify-content: space-between;
}

.pr-0 {
  padding-right: 0;
}

.py-1\.5{
  padding-top:0.375rem !important;
  padding-bottom:0.375rem !important;
}

.lg\:w-2\/10 {
  width:20%
}

.lg\:w-7\/12 {
  width: 58.333333%;
}

.sm\:w-2\/10{
  width:20%
}
.sm\:w-3\/12{
  width:25%
}
.sm\:w-6\/12{
  width:50%
}
.sm\:w-4\/12{
  width:33.333333%
}

.banner a.px-5 {
  padding-left: 0;
  padding-right: 1.25rem;
}

.banner #navbar-ios, .banner #navbar-android {
  display: none
}

.css-ohwg9z, .jss1 {
  overflow: visible !important;
}

.bg-video {
  width: 90%;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.bg-proteccion {
  margin-top: -32.125rem;
}

.bg-phone .folder {
  width: 400px;
  margin-bottom: 4.5rem;
  margin-left: 4rem;
}

.bg-phone .piggyBank {
  width: 290px;
  margin-left: 4rem;
}

.bg-phone .call {
  width: 300px;
  margin-right: 8rem;
}

.bg-phone .ambulance {
  width: 345px;
  margin-right: 10rem;
}

.zoom {
  width: 350px;
  animation: zoom-out 1s ease 1 both;
}

.zoom:hover{
  animation: zoom-in 1s ease 1 both;
}

.carousel {
  padding-top: 150px;
}

.header-padding {
  padding-top: 18.625rem;
}

.form-padding {
  padding-left: 4.75rem;
}

.bl-white{
  border-left: 2px solid white;
}

.logo-header {
  width: 75%;
  padding-left: 1rem;
}

.logo-header svg {
  width: 19%;
}

.bg-success {
  height: 100vh;
  background-image: url('/src/assets/img/bg-footer.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.float-faq {
  position: fixed;
  bottom: 1.75rem;
  right: 1rem;
}

@keyframes zoom-in {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1.75, 1.75);
  }
}

@keyframes zoom-out {
  0% {
    transform: scale(1.75, 1.75);
  }
  100% {
    transform: scale(1, 1);
  }
}

/*****/
@media (max-width: 1536px){
  .bg-free {
    height: calc(100vw*0.55);
    margin-top: 10.625rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
	  line-height: 2.5rem;
  }
  
}

@media (max-width: 1240px){
  .md\:text-md {
    font-size: 0.75rem;
  }
}

@media (max-width: 1080px){
  .md\:text-md {
    font-size: 0.625rem;
  }
}

@media (max-width: 1024px){
  .height-slider {
    height: 465px;
  }
  .bg-free {
    margin-top: 0.625rem;
  }
  .bg-acto {
    height: 7rem;
  }
  /*
  .text-40 {
    font-size: 2rem;
  }*/
  .text-42 {
    font-size: 1.625rem;
  }
  .md\:text-4xl {
    font-size: 2.25rem;
  }
  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .bg-experience {
    height: 54rem;
    margin-bottom: -14rem;
  }
  .bg-style {
    background-size: cover;
    background-position-x: 76%;
    margin-top: 5.5rem;
    height: 613px;
    margin-bottom: 2rem;
  }
  .bg-benefits-assistance {
    height: 40rem;
    margin-bottom: 10rem;
  }
  .bg-benefits-protections {
    height: 50rem;
    margin-bottom: 38rem;
  }

  .bg-app {
    margin-top: 27rem;
  }

  .bg-faro.md-hidden .container {
    padding-top: 2rem;
  }

  .text-26 {
    font-size: 1.25rem;
	  line-height: 1.25rem;
  }

  .bg-phone {
    height: 995px;
  }

  .bg-proteccion{
    margin-top: -4.5rem;
  }

  .bg-phone .folder {
    width: 330px;
  }
  
  .bg-phone .piggyBank {
    width: 286px;
  }
  
  .bg-phone .call {
    width: 289px;
  }
  
  .bg-phone .ambulance {
    width: 290px;
  }

  .bg-faro {
    margin-top: 0;
  }

  .carousel {
    padding-top: 13rem;
  }

  .header-padding {
    padding-top: 9.625rem;
  }

  .form-padding {
    padding-left: 0;
  }
}

@media (max-width: 820px){
  .zoom {
    width: 250px;
    animation: none;
  }

  .bg-faro {
    padding: 10rem 0;
  }
  .bg-faro .container .flex.flex-wrap.text-left {
    align-items: center;
  }
  .bg-moment {
    height: 40rem;
    margin-top: 0;
  }
  .h-px {
    height: 2px;
  }
  .logo-ios{
    width: 168px;
  }

  .logo-android {
    width: 168px;
  }
  .bg-benefits-assistance {
    margin-bottom: 0;
  }
  .bg-benefits-protections {
    margin-bottom: 0;
  }
  .md-bg-white {
    background-color: #F7F7F7;
    margin-left: -19px;
    margin-top: 35px;
    border-radius: 0 0 5rem 0;
    flex-grow: unset;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  .NavbarContainer {
    padding-top: 0.5rem;
    height: 2.25rem;
  }
  .hover\:bg-white:hover {
    background-color: transparent;
  }
  .bg-app {
    margin-top: 0;
  }
  .logo-header {
    width: 60%;
  }
  .bg-phone {
    background-image: url('/src/assets/img/mobile/bg-phone.png');
    height: calc(100vw*1.68);
    background-size: cover;
  }

  .bg-experience {
    background-image: url('/src/assets/img/mobile/bg-experience.png');
    background-position-x: inherit;
    height: 36rem;
    margin-top: -3.75rem;
  }

  .text-menu {
    color: #741BC0;
    font-weight: 300;
  }

  .banner #navbar-ios {
    display: block;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
  
  .banner #navbar-android {
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }

  .banner a.py-4 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .banner a.px-5 {
    padding-left: 3.875rem;
    padding-right: 1.625rem;
  }

  .bg-blue-acto {
    margin-top: 0;
  }

  .btn-20 {
    width: 100%;
  }
  .bg-acto-play {
    width: 726px;
    height: 423px;
  }

  .bg-form {
    height: 100%;
  }

  .height-slider {
    height: 465px;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
  }
}

@media (max-width: 1023.5px) {
  .md-hidden {
    display: none;
  }
  
  .bg-acto {
    background-color: rgba(255, 255, 255, 0.9);
  }

  .makeStyles-root-1 {
    overflow: visible !important;
  }
}

@media (min-width: 1024px){
  .lg\:w-2\/12{
    width: 16.666667%;
  }
}

@media (max-width: 432px){
  .bg-acto {
    background-image: url('/src/assets/img/mobile/bg-navbar.svg');
    height: 6rem;
    background-position-x: 11%;
    padding: 0.25rem;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .bg-free {
    background-image: url('/src/assets/img/mobile/bg-mobile.png');
    background-size: cover;
    background-position: center;
    height: calc(100vw*1.46);
  }

  .bg-blue-acto {
    background-image: url('/src/assets/img/mobile/bg-video.png');
    height: 250px;
    background-size: cover;
    background-position: center;
    margin-top: 3rem;
  }

  .bg-assistance {
    height: auto;
  }

  .bg-experience {
    background-image: url('/src/assets/img/mobile/bg-experience.png');
    background-position-x: inherit;
    height: 18rem;
    margin-top: -3.75rem;
    margin-bottom: 0;
  }

  .bg-protection {
    height: auto;
  }
  /*
  .text-40 {
    font-size: 1.5rem;
  }*/

  .bg-faro {
    background-image: url('/src/assets/img/mobile/bg-faro.png');
    height: 33rem;
    background-size: cover;
    margin-top: 2rem;
  }

  .bg-faro .container {
    margin-top: -2rem;
  }

  .bg-moment {
    background-image: url('/src/assets/img/mobile/bg-moment.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 534px;
    margin-top: 6.25rem;
  }

  .bg-app {
    background-image: url('/src/assets/img/mobile/bg-download.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 31%;
    height: 28rem;
    margin-top: 0;
  }

  .bg-phone {
    background-image: url('/src/assets/img/mobile/bg-phone.png');
    height: calc(100vw*1.71);
    background-size: cover;
    margin-top: 2rem;
  }

  .height-slider {
    height: 465px;
  }

  .logo-header {
    width: 30%;
    padding-left: 0.5rem;
  }
  .logo-header svg{
    width: 48px;
  }
  .bg-benefits-assistance {
    margin-top: 0;
    height: 365px;
  }
  .bg-benefits-protections {
    height: 542px;
  }

  .logo-mobile-gray {
    height: 1.25rem;
    width: 2.75rem;
  }

  .btn-20 {
    width: 20rem;
  }

  .btn-acto {
    width: 90%;
    font-size: 18px;
    padding: 18px 0;
  }

  .bg-acto-play {
    width: 264px;
    height: 190px;
    border-radius: 1.5rem;
  }

  .bg-form {
    width: 100%;
    height: auto;
    margin: 0.5rem 2.25rem;
    border-radius: 2rem;
    padding: 1.25rem;
  }

  .behind {
    height: 282px;
    margin: 12.75rem 0rem 0rem 4.5rem;
    border-radius: 1.5rem;
  }

  .md\:text-sm {
    font-size: 0.875rem;
	  line-height: 1.25rem;
  }

  .md\:text-lg{
    font-size:1.125rem !important;
    line-height:1.75rem
  }

  .md\:py-4{
    padding-top: 0.5rem;
	  padding-bottom: 0.5rem;
  }

  .md-bg-white {
    margin-top: 27px;
  }

  .bg-success {
    background-image: url('/src/assets/img/mobile/bg-footer.png');
  }
}

@media (max-width: 390px){
  .logo-header {
    width: 24%;
  }
  
  .text-xs {
    font-size: 10px;
  }

  .px-6 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}